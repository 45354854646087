import React from 'react';
import Download from "../../icons/download";
import Img from "../../icons/img";
import Pdf from "../../icons/pdf";
import styles from './styles.module.scss';

const Item = (props) => {
    return (
        <div className={styles.item}>
            {props.type === 'image' ? <Img /> : <Pdf />}
            <div>
                <p className="text-md">{props.title}</p>
                <a href={props.url} className="text-dark-2 text-base gap-2 underline hover:no-underline btn" download={true}>
                    Download <Download />
                </a>
            </div>
        </div>
    );
};

export default function Documentation({ currentPhase = "execution", chapters }) {
    if (!chapters || chapters.length === 0) {
        return <div>Loading...</div>;
    }

    // Filter chapters based on the current phase
    const phaseChapters = chapters.filter(chapter => chapter.fase === currentPhase);

    return (
        <div className="w-full">
            <div className="flex flex-col items-center text-center gap-8">
                <div>
                    <b className="text-primary-0 text-base">Documentation</b>
                    <h2 className="font-baloo text-3xl">{currentPhase}</h2>
                </div>
                <div className="gap-inherit flex 2xl:flex-row flex-col">
                    {phaseChapters.map((chapter, index) => (
                        chapter.files.map((file, fileIndex) => (
                            <Item 
                                key={`${index}-${fileIndex}`}
                                type={file.type}
                                title={file.title}
                                url={file.url}
                            />
                        ))
                    ))}
                </div>
            </div>
        </div>
    );
}
