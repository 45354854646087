import Download from "../../icons/download"

const Item = (props) => (
    <label className="text-md rounded-xl cursor-pointer bg-primary-6 text-center md:min-w-64 md:w-64 border border-primary-1 has-[input:checked]:border-primary-0 hover:border-primary-0 p-5">
        <input value={props.title} defaultChecked={props.checked} type="radio" name="home-pdf" className="hidden" />
        <Download className="md:w-12 w-11 mx-auto" />
        <span className="block mt-2">{props.title}</span>
        <a href={props.href} className="text-dark-2 text-base gap-2 underline hover:no-underline btn" download={true} target="_blank" rel="noreferrer">Download <Download /></a>
    </label>
)

export default function PDFs({ project }) {
    
    return (
        <div className="md:flex grid grid-cols-2 md:gap-8 gap-5 items-start flex-wrap">
            {project.Attachments && project.Attachments.length > 0 ? (
                console.log("heeeelp"),
                project.Attachments.map((attachment, index) => (
                    <Item
                        key={index}
                        href={attachment}
                        checked={attachment.checked}
                        title="PDF"
                    />
                ))
            ) : (
                <p className="text-center w-full">There are no files</p>
            )}
        </div>
    );
}
