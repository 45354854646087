import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import projectService from '../services/projects.ts';
import Layout from '../hoc/layout';
import Process from '../components/process';
import Carousel from '../components/carousel';
import Documentation from '../components/documentation';

const PHASES = [
    { id: 'creation', name: 'Creación', date: 'October 15, 2024' },
    { id: 'design', name: 'Diseño', date: 'November 20, 2024' },
    { id: 'execution', name: 'Ejecución', date: 'December 8, 2024' },
    { id: 'delivery', name: 'Entrega', date: 'January 1, 2025' }
];

export default function Project() {
    const [currentPhaseIndex, setCurrentPhaseIndex] = useState(2); // Starting with execution phase
    const currentPhase = PHASES[currentPhaseIndex];
    const [chapters, setChapters] = useState([]);
    const [projects, setProjects] = useState([]);
    const { id } = useParams();

    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const projects = await projectService.getProjectById(id);
                setProjects(projects);
            } catch (error) {
                console.error('Error fetching projects:', error);
            }
        };

        const fetchChapters = async () => {
            try {
                const chapters = await projectService.getChaptersById(id);
                setChapters(chapters);
            } catch (error) {
                console.error('Error fetching chapters:', error);
            }
        };

        fetchProjects();
        fetchChapters();
    }, [id]);

    // Find the chapter that matches the current phase
    const currentPhaseChapter = chapters.find(chapter => chapter.Fase === currentPhase.name);

    return (
        <Layout>
            <b className="font-verdana mx-auto py-[0.285em] bg-primary-1 rounded-full text-primary-3 px-[1em]">{currentPhaseChapter?.Proyecto}</b>
            <h1 className="font-baloo text-5xl mx-auto md:mt-4 md:mb-5 my-3 max-w-5xl text-center ">{projects.Cliente} , your mould is in progress</h1>
            <p className="mx-auto text-primary-4 text-xl">{currentPhaseChapter?.PorcentajeAvance} completed</p>
            <img
src="https://moldtechsl-my.sharepoint.com/personal/alvaro_rocha_moldtechsl_es/Documents/Proyectos/Proyecto%20MARAVISH/Reserved_ImageAttachment_%5B14%5D_%5BImagendelmolde%5D%5B62%5D_%5BPROY23-003-ELENOR-Molde-Viga-T_Camara_Camara_05_Perspectiva_01%5D%5B1%5D_%5B2%5D.png?web=1"
alt="SharePoint"
/>
            <p className="text-base mx-auto font-verdana text-dark-2">STATIONARY TABLES W=1,6M L=100M: F=60%</p>
            <div className="lg:my-[3.25rem] text-center md:my-11 my-8">
                <h4 className="text-md font-bold text-primary-0">We are working on your order</h4>
                <h2 className="text-3xl font-baloo my-3">Chapter phases</h2>
                <p className="text-dark-2 text-xl">Estimated delivery: {currentPhaseChapter?.FechaFinCapitulo} </p>
            </div>
            <Process 
                currentPhaseIndex={currentPhaseIndex} 
                onPhaseChange={setCurrentPhaseIndex}
            />
            
            {(currentPhase.id === 'execution' || currentPhase.id === 'delivery') ? (
                <Carousel phase={currentPhase.id} chapters={chapters}/>
            ) : (
                <div className="lg:my-16 md:my-12 my-8">
                    <Documentation currentPhase={currentPhase.id} chapters={chapters} />
                </div>
            )}

            <div className="text-center lg:my-24 my-12">
                <b className="text-base text-primary-0">Next phase:</b>
                <h2 className="font-baloo text-3xl mt-3 mb-5">
                    {currentPhaseIndex < PHASES.length - 1 
                        ? PHASES[currentPhaseIndex + 1].name 
                        : 'Installation'}
                </h2>
            </div>
        </Layout>
    );
}
