import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import projectService from '../../services/projects.ts';

const Item = props => (
    <div className="flex sm:items-center flex-col sm:flex-row justify-between text-dark-2 gap-2">
        <div>
            <h4 className="text-lg">
                {props.medicine} {props.width && `W=${props.width}`} {props.length && `L=${props.length}`}: {props.fill && <>F=<b className="text-primary-0">{props.fill}</b></>}
            </h4>
        </div>
        <Link to={props.link} className="btn-primary text-base">Go to chapter</Link>
    </div>
);

export default function Medicines({ projectId }) {
    const [items, setItems] = useState([]);

    useEffect(() => {
        const fetchChapters = async () => {
            try {
                const chapters = await projectService.getChapters();
                const filteredChapters = chapters.filter(chapter => chapter.IdProyecto === projectId);

                const uniqueChapters = filteredChapters.filter((chapter, index, self) =>
                    index === self.findIndex((c) => c.Capitulo === chapter.Capitulo)
                );

                setItems(uniqueChapters);
            } catch (error) {
                console.error('Error fetching chapters:', error);
            }
        };

        fetchChapters();
    }, [projectId]);

    return (
        <div>
            {items.map((item, index) => (
                <Item 
                    key={index}
                    medicine={item.Capitulo}
                    link={`/chapter/${item.IdProyecto}`}
                />
            ))}
        </div>
    );
}
