import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Layout from "../../hoc/layout";
import projectService from '../../services/projects.ts';
import PDFs from './pdfs';
import Medicines from './medicines';
import Tabs from '../../ui/tabs';

const Users = () => {
    const [selectedTab, setSelectedTab] = useState(0);
    const [projects, setProjects] = useState([]);
    const [userProjects, setUserProjects] = useState([]);

    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const projects = await projectService.getProjects();
                setProjects(projects);

                const user = JSON.parse(localStorage.getItem('user'));
                console.log(user, "USER");
                if (user && user.projects) {
                    const userProjectDetails = projects.filter(project => user.projects.includes(project._id));
                    setUserProjects(userProjectDetails);
                    console.log(userProjectDetails, "AAAAA");
                }
            } catch (error) {
                console.error('Error fetching projects:', error);
            }
        };

        fetchProjects();
    }, []);

    const handleTabChange = (index) => {
        setSelectedTab(index);
    };

    const renderContent = () => {
        if (userProjects.length === 0) return null;

        const selectedProject = userProjects[selectedTab];
        console.log(selectedProject, "SELECTED PROJECT");
        if (!selectedProject) return null;

        return (
            <>
                <PDFs project={selectedProject} />
                <h3 className="text-2xl font-baloo mt-resp-1 lg:mb-8 md:mb-6 mb-4">
                    Chapters of the Document
                </h3>
                <Medicines projectId={selectedProject.IdProyecto} />
            </>
        );
    };

    return (
        <Layout>
            <h1 className="font-baloo text-5xl mb-resp-1">
                Welcome {projects.length > 0 ? projects[0].Cliente : 'Guest'}
            </h1>
            <h2 className="font-baloo text-3xl md:mb-10 mb-8">General Project Documentation</h2>
            <Tabs 
                className="text-xl overflow-auto [&>button]:h-10 xl:gap-16 md:gap-12 gap-8 flex" 
                options={userProjects.map(project => project.Proyecto)}
                onChange={handleTabChange}
                value={selectedTab}
            />
            <hr className="mt-6 mb-resp-2 border-light-4" />
            {renderContent()}
        </Layout>
    );
};

export default Users;
