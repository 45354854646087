import axios from 'axios';

const API_URL = 'http://mymoldtechsl.es/api';

interface LoginResponse {
    _id: string;
    email: string;
    password: string;
    role: string;
}

interface LoginRequest {
    email: string;
    password: string;
}

export const loginService = {
    async login(data: LoginRequest): Promise<LoginResponse> {
        try {
            const response = await axios.post<LoginResponse>(`${API_URL}/users/login`, data);
            const user = response.data;
            console.log('User role:', user.role, response); // Log user role
            // Store user information and role in local storage
            localStorage.setItem('user', JSON.stringify(user));
            localStorage.setItem('role', user.role); // Store user role
            return user;
        } catch (error) {
            throw new Error('Login failed');
        }
    },

    async logout(): Promise<void> {
        try {
            // Remove user information and role from local storage
            localStorage.removeItem('user');
            localStorage.removeItem('role'); // Remove user role
            await axios.post(`${API_URL}/logout`);
        } catch (error) {
            throw new Error('Logout failed');
        }
    }
};

export default loginService;
