import React, { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";
import Up from "../../icons/up";
import Bars from "../../icons/bars";
import Close from "../../icons/close";
import projectService from '../../services/projects.ts';
import styles from './styles.module.scss';

const Expandable = (props) => {
    const [open, setOpen] = useState(false);
    return (
        <>
            <button onClick={() => setOpen(!open)}>{props.name} <Up className={open ? '' : 'rotate-180'} /> </button>
            <ul className={`${styles.sublinks} ${open ? 'max-h-96' : 'max-h-0 opacity-0'}`}>
                {props.sublinks.map((sublink, index) => (
                    <li key={index}>
                        <NavLink to={sublink.to}>{sublink.name}</NavLink>
                    </li>
                ))}
            </ul>
        </>
    );
};

export default function Drawer() {
    const [open, setOpen] = useState(false);
    const [role, setRole] = useState('');
    const [projects, setProjects] = useState([]);
    const user = JSON.parse(localStorage.getItem('user'));

    useEffect(() => {
        const userRole = localStorage.getItem('role');
        setRole(userRole);

        const fetchProjects = async () => {
            try {
                const projects = await projectService.getProjects();
                const user = JSON.parse(localStorage.getItem('user'));
                if (user && user.projects) {
                    const userProjectDetails = projects.filter(project => user.projects.includes(project._id));
                    setProjects(userProjectDetails);
                    console.log(userProjectDetails, "DRAWER");
                }
            } catch (error) {
                console.error('Error fetching projects:', error);
            }
        };

        fetchProjects();
    }, []);

    return (
        <>
            {open && <div className="lg:hidden z-50 bg-black bg-opacity-40 fixed top-0 left-0 w-full h-full" />}

            <div className={`bg-primary-0 xl:px-8 z-50 md:py-14 md:px-6 pb-8 pt-28 p-4 text-white h-dvh flex flex-col md:gap-10 gap-6 overflow-auto lg:sticky top-0 fixed transition-all ${styles.root} ${open ? 'left-0' : '-left-56'}`}>
                <NavLink to="/dashboard">
                    <img 
                        src="/logo.png" 
                        alt="" 
                        className="md:block hidden mt-2 h-10 w-auto object-contain" 
                    />
                </NavLink>
                <hr className="text-primary-2 border-current md:block hidden" />
                <div>
                    <img src="/avatar.png" className="w-10 h-10 object-cover rounded-full object-center" alt="" />
                    <p className="text-primary-1 mt-2">{user.email}</p>
                </div>
                <ul className={styles.links}>
                    <Expandable 
                        name="Projects"
                        sublinks={projects.map((project, index) => ({
                            name: project.Proyecto,
                            to: `/chapter/${project.IdProyecto}`
                        }))}
                    />
                    {role === 'admin' && (
                        <li>
                            <NavLink to="/dashboard/users">Users</NavLink>
                        </li>
                    )}
                    <li>
                        <NavLink to="/">Log Out</NavLink>
                    </li>
                </ul>
            </div>
            <nav className="flex sticky top-0 lg:hidden items-center z-50 justify-between bg-primary-0 py-3 px-8">
                <NavLink to="/dashboard">
                    <img alt="" src="/logo.png" className="h-10" />
                </NavLink>
                <button onClick={() => setOpen(!open)}>
                    {open ? <Close /> : <Bars />}
                </button>
            </nav>
        </>
    );
}
