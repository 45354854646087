import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Layout from "../hoc/layout";
import projectService from '../services/projects.ts';

const API_URL = 'http://mymoldtechsl.es/api/users';

const Users = () => {
    const [users, setUsers] = useState([]);
    const [projects, setProjects] = useState([]); // State for projects
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedProjects, setSelectedProjects] = useState([]);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await axios.get(API_URL);
                console.log(response.data);
                setUsers(response.data);
            } catch (error) {
                console.error('Error fetching users:', error);
                setError('Error fetching users. Please try again later.');
            }
        };

        const fetchProjects = async () => {
            try {
                const projects = await projectService.getProjects();
                setProjects(projects);
            } catch (error) {
                console.error('Error fetching projects:', error);
                setError('Error fetching projects. Please try again later.');
            }
        };

        fetchUsers();
        fetchProjects();
    }, []);

    const handleDelete = async (userId) => {
        try {
            await axios.delete(`${API_URL}/${userId}`);
            setUsers(users.filter(user => user.id !== userId));
        } catch (error) {
            console.error('Error deleting user:', error);
            setError('Error deleting user. Please try again later.');
        }
    };

    const handleAddUser = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post("http://mymoldtechsl.es/api/users/create", { email, password });
            setUsers([...users, response.data]);
        
            setEmail('');
            setPassword('');
        } catch (error) {
            console.error('Error adding user:', error);
            setError('Error adding user. Please try again later.');
        }
    };

    const handleAssignProjects = async () => {
        if (!selectedUser || selectedProjects.length === 0) {
            setError('Please select a user and at least one project.');
            return;
        }

        try {
            const response = await axios.put(`${API_URL}/${selectedUser}`, { projectIds: selectedProjects });
            setUsers(users.map(user => user.id === selectedUser ? { ...user, projectIds: selectedProjects } : user));
            setSelectedUser(null);
            setSelectedProjects([]);
        } catch (error) {
            console.error('Error assigning projects:', error);
            setError('Error assigning projects. Please try again later.');
        }
    };

    const handleProjectSelection = (projectId) => {
        setSelectedProjects(prevSelectedProjects => {
            if (prevSelectedProjects.includes(projectId)) {
                return prevSelectedProjects.filter(id => id !== projectId);
            } else {
                return [...prevSelectedProjects, projectId];
            }
        });
    };

    return (
        <Layout>
            <div>
                <h1>Users List</h1>
                {error && <p className="text-red-500">{error}</p>}
                <table className="min-w-full bg-white">
                    <thead>
                        <tr>
                            <th className="py-2">Name</th>
                            <th className="py-2">Email</th>
                            <th className="py-2">Role</th>
                            <th className="py-2">Projects</th>
                            <th className="py-2">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map(user => (
                            <tr key={user.id}>
                                <td className="border px-4 py-2">{user.name}</td>
                                <td className="border px-4 py-2">{user.email}</td>
                                <td className="border px-4 py-2">{user.role}</td>
                                <td className="border px-4 py-2">
                                    {user.projects ? user.projects.map(projectId => {
                                        const project = projects.find(p => p._id === projectId);
                                        return project ? project.Proyecto : projectId;
                                    }).join(', ') : 'No projects assigned'}
                                </td>
                                <td className="border px-4 py-2">
                                    <button 
                                        className="bg-blue-500 text-white px-2 py-1 rounded mr-2"
                                        onClick={() => setSelectedUser(user.id)}
                                    >
                                        Assign Projects
                                    </button>
                                    <button 
                                        className="bg-red-500 text-white px-2 py-1 rounded"
                                        onClick={() => handleDelete(user.id)}
                                    >
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <h2 className="mt-6">Add User</h2>
                <form onSubmit={handleAddUser} className="mt-4">
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                            Email
                        </label>
                        <input 
                            type="email" 
                            id="email" 
                            value={email} 
                            onChange={(e) => setEmail(e.target.value)} 
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            required 
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
                            Password
                        </label>
                        <input 
                            type="password" 
                            id="password" 
                            value={password} 
                            onChange={(e) => setPassword(e.target.value)} 
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            required 
                        />
                    </div>
                    <button 
                        type="submit" 
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    >
                        Add User
                    </button>
                </form>
                <h2 className="mt-6">Assign Projects</h2>
                <div className="mt-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="user">
                        Select User
                    </label>
                    <select 
                        id="user" 
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        onChange={(e) => setSelectedUser(e.target.value)}
                        value={selectedUser || ""}
                    >
                        <option value="">Select a user</option>
                        {users.map(user => (
                            <option key={user.id} value={user.id}>{user.email}</option>
                        ))}
                    </select>
                    <div className="mt-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="projects">
                            Select Projects
                        </label>
                        <div className="projects-list mb-4">
                            {projects.map(project => (
                                <div key={project._id} className="mb-2">
                                    <input 
                                        type="checkbox" 
                                        id={`project-${project._id}`} 
                                        value={project._id}
                                        onChange={() => handleProjectSelection(project._id)}
                                    />
                                    <label htmlFor={`project-${project._id}`} className="ml-2">{project.Proyecto}</label>
                                </div>
                            ))}
                        </div>
                        <button 
                            className="bg-green-500 text-white px-4 py-2 rounded mr-2"
                            onClick={handleAssignProjects}
                        >
                            Assign Selected Projects
                        </button>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default Users;
