import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Star from "../icons/star";
import Checkbox from "../ui/checkbox";
import Input from "../ui/input";
import Password from "../ui/password";
import { loginService } from "../services/login.ts";

export default function Login() {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");

    const onSubmit = async (e) => {
        e.preventDefault();
        try {
            console.log("Logging in...");
            const response = await loginService.login({ email, password });
            console.log(response);

            // Save the token or user info as needed
            navigate('/dashboard');
        } catch (err) {
            console.error("Error logging dexedxedxedxe1rderd4ereerwdin:", err);
            setError("Login failed. Please check your credentials and try again.");
        }
    };

    return (
        <div className="flex">
            <div className="relative flex-1 lg:block hidden">
                <img alt="" className="h-dvh object-cover object-center sticky top-0" src="/login-cover.webp" />
                <div className="2xl:px-14 xl:p-8 p-6 flex flex-wrap justify-center gap-2 font-baloo leading-inherit absolute text-md text-center bg-dark-4 bg-opacity-40 rounded-xl xl:left-8 xl:right-8 xl:bottom-8 left-4 bottom-4 right-4 text-white">
                    <p>The molds designed by Moldtech are intended to ensure that the elements produced are precise and uniform. Further more the technology used in the manufacturing efficient & cost-effective production.</p>
                    <Star />
                    <Star />
                    <Star />
                    <Star />
                    <Star />
                </div>
            </div>
            <div className="flex-1 text-dark-5 flex flex-col items-center px-resp justify-center">
                <div className="sm:w-[28rem] my-resp-1">
                    <img src="/logo-blue.png" className="max-w-[80%] mx-auto" alt="" />
                    <h1 className="text-2xl font-baloo mt-resp-3 lg:mb-8 mb-6">Welcome to Mymoldtechsl.es</h1>
                    <form onSubmit={onSubmit} className="grid">
                        <Input title="Email" placeholder="Enter your email" value={email} onChange={(e) => setEmail(e.target.value)} />
                        <Password placeholder="Enter password" title="Password" className="md:mt-6 mt-5" value={password} onChange={(e) => setPassword(e.target.value)} />
                        <Checkbox title="T&C" className="my-[0.875rem]" />
                        <button className="btn-primary-v2 text-base">Login</button>
                        {error && <p className="text-red-500 mt-2">{error}</p>}
                    </form>
                    <p className="lg:mt-10 md:mt-8 mt-6">If you do not have an account, request it at info@moldtechsl.es</p>
                </div>
            </div>
        </div>
    );
}
