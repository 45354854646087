import axios from 'axios';

const API_URL = 'http://mymoldtechsl.es/api';

interface Project {
    _id: string;
    Proyecto: string;
    IdProyecto: string;
    FechaFinProyecto: string;
    Cliente: string;
    Psw: string;
    Usuario: string;
}

interface Chapter {
    _id: { $oid: string };
    Proyecto: string;
    Capitulo: string;
    FechaFinCapitulo: string;
    PorcentajeAvance: string;
    Fase: string;
    Faseinactiva: string;
    Observaciones: string;
    IdProyecto: string;
    Attachments: string;
    FaseVisible: string;
    Foto1: string;
    Foto2: string;
    Foto3: string;
    Foto4: string;
}

export const projectService = {

async getProjects(): Promise<Project[]> {
    try {
        const response = await axios.get<Project[]>(`${API_URL}/projects/getAllProjects`);
        console.log('Projects:', response.data); // Log projects
        return response.data;
    } catch (error) {
        throw new Error('Failed to fetch projects');
    }
},

async getChapters(): Promise<Chapter[]> {
    try {
        const response = await axios.get<Chapter[]>(`${API_URL}/projects/getAllChapters`);
        console.log('Chapters:', response.data); // Log chapters
        return response.data;
    } catch (error) {
        throw new Error('Failed to fetch chapters');
    }
},

async getProjectById(id: string): Promise<Project> {
    try {
        const response = await axios.get<Project>(`${API_URL}/projects/getProjectById/${id}`);
        console.log('Project:', response.data); // Log project
        return response.data;
    } catch (error) {
        throw new Error(`Failed to fetch project with id ${id}`);
    }
},

async getChaptersById(id: string): Promise<Chapter[]> {
    try {
        const response = await axios.get<Chapter[]>(`${API_URL}/projects/chapterByProjectId/${id}`);
        console.log('Chapters:', response.data); // Log chapters
        return response.data;
    } catch (error) {
        throw new Error(`Failed to fetch chapters with project id ${id}`);
    }
}

};
export default projectService;
